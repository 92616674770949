import firebaseClient from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'

const CLIENT_CONFIG =
  process.env.NODE_ENV === 'production'
    ? {
        apiKey: 'AIzaSyAFQtdWKxw-4zuGcAgAb_HiT0zBbhOUa2E',
        authDomain: 'codingfantassy-prod.firebaseapp.com',
        projectId: 'codingfantassy-prod',
        storageBucket: 'codingfantassy-prod.appspot.com',
        messagingSenderId: '378820506754',
        appId: '1:378820506754:web:7e0e1185c59b0ed713b09b',
        measurementId: 'G-CBY3LNJW43',
        databaseURL: `codingfantassy-prod`,
      }
    : {
        apiKey: 'AIzaSyCybTBhcBbU_TuL1J9EZYKr8U8Gr2CzWkc',
        authDomain: 'codingfantasy-a88fc.firebaseapp.com',
        projectId: 'codingfantasy-a88fc',
        storageBucket: 'codingfantasy-a88fc.appspot.com',
        messagingSenderId: '142203793501',
        appId: '1:142203793501:web:8f81a7906ea8036e499db4',
        measurementId: 'G-M439XQLR7Z',
        databaseURL: `https://codingfantasy-a88fc.firebaseio.com`,
      }

if (typeof window !== 'undefined' && !firebaseClient.apps.length) {
  firebaseClient.initializeApp(CLIENT_CONFIG)

  try {
    firebaseClient.firestore().enablePersistence({ synchronizeTabs: true })
  } catch (error) {
    console.log('😡', error)
  }

  window.firebase = firebaseClient
}

export { firebaseClient }
