import React, { useState, useEffect, createContext } from 'react'
import nookies from 'nookies'

import { firebaseClient } from 'lib/firebaseClient'
import { USER_GAME_STATS } from 'lib/constants'

export type User = firebaseClient.User & {
  profile: { userName: string; games: USER_GAME_STATS[]; uid: string; betaTestCode?: string }
}

export const AuthContext = createContext<{
  user: User | null
  isLoaded: boolean
  loading: boolean
}>({
  user: null,
  isLoaded: false,
  loading: false,
})

export default function AuthProvider({ children }: any) {
  const [user, setUser] = useState<User | null>(null)

  const [isLoaded, setLoaded] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.nookies = nookies
    }

    return firebaseClient.auth().onIdTokenChanged(async (user) => {
      // console.log(`token changed!`)

      setLoading(true)

      if (!user) {
        // console.log(`no token found...`)
        setUser(null)

        nookies.destroy(null, 'token')
        nookies.set(null, 'token', '', { path: '/' })

        setLoading(false)
        setLoaded(true)

        return
      }

      // console.log(`updating token...`)

      const token = await user.getIdToken()

      setUser(user)

      setLoading(false)
      setLoaded(true)

      nookies.destroy(null, 'token')
      nookies.set(null, 'token', token, { path: '/' })
    })
  }, [])

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      // console.log(`refreshing token...`)
      const user = firebaseClient.auth().currentUser

      if (user) await user.getIdToken(true)
    }, 10 * 60 * 1000)

    return () => clearInterval(handle)
  }, [])

  useEffect(() => {
    if (user && !isLoaded) {
      setLoaded(true)
    }
  }, [user, isLoaded, setLoaded, setLoading, loading])

  // console.log('user:', user, 'isLoaded:', isLoaded)
  return <AuthContext.Provider value={{ user, isLoaded, loading }}>{children}</AuthContext.Provider>
}
